import { ErrorFallbackProps, ErrorComponent, ErrorBoundary, AppProps } from "@blitzjs/next"
import { AuthenticationError, AuthorizationError } from "blitz"
import React, { createContext, useEffect, useMemo, useRef, useState } from "react"
import { withBlitz } from "app/blitz-client"
import { useRouter } from "next/router"
import { Analytics } from "@vercel/analytics/react"
import { useQuery } from "@blitzjs/rpc"
import {
  ChakraProvider,
  extendTheme,
  type ThemeConfig,
  cookieStorageManager,
} from "@chakra-ui/react"
import getCurrentUser, { CurrentUser } from "app/users/queries/getCurrentUser"
import { accountsFromUser } from "app/core/components/AuthButtons"
import { Howl } from "howler"
import { PlaylistModal } from "app/core/components/PlaylistModal"
import { MDXProvider } from "@mdx-js/react"
import MDXComponents from "app/mdxComponents"

type PlayerContextT = {
  play: (url: string[]) => void
  url?: string
  state: "playing" | "paused" | "loading"
  setPlaylistModalMode: (mode: PlaylistModalMode) => void
}

export const PlayerContext = createContext<PlayerContextT | null>(null)

const config: ThemeConfig = {
  useSystemColorMode: true,
  initialColorMode: "system",
}

export type PlaylistModalMode = "scope" | "create" | null

export type RefetchMethod = ReturnType<typeof useQuery>[1]["refetch"]
export type UserContextT = {
  user: CurrentUser
  refetch: RefetchMethod
  isLoading: boolean
  isFetched: boolean
  // userCount: number | null
  // like?: (id: string) => void
}

const FakeRefetch = (() => {}) as RefetchMethod

export const UserContext = createContext<UserContextT>({
  user: null,
  refetch: FakeRefetch,
  isLoading: false,
  isFetched: false,
  // userCount: 0,
})

const theme = extendTheme({
  config,
  styles: {
    global: {
      "html, body": {
        scrollBehavior: "smooth",
      },
    },
  },
  // colors: {
  //   orange: {
  //     // 500: "#FC4C02",
  //     300: "#ffffff",
  //   },
  // },
})

function RootErrorFallback({ error }: ErrorFallbackProps) {
  if (error instanceof AuthenticationError) {
    return <div>Error: You are not authenticated</div>
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent
        statusCode={(error as any)?.statusCode || 400}
        title={error.message || error.name}
      />
    )
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const getLayout = Component.getLayout || ((page) => page)
  const router = useRouter()

  // const colorModeManager =
  //   typeof pageProps.cookies === "string"
  //     ? cookieStorageManagerSSR(pageProps.cookies)
  //     : cookieStorageManager

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (
        typeof window !== "undefined" &&
        // @ts-ignore
        typeof window.__STRAVA_EMBED_BOOTSTRAP__ === "function"
      ) {
        setTimeout(() => {
          // @ts-ignore
          window.__STRAVA_EMBED_BOOTSTRAP__()
        }, 1000)
      }
    }

    router.events.on("routeChangeComplete", handleRouteChange)
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  return (
    <MDXProvider components={MDXComponents}>
      <ChakraProvider theme={theme} colorModeManager={cookieStorageManager}>
        <ErrorBoundary FallbackComponent={RootErrorFallback}>
          <UserWrapper>
            <PlayerWrapper>{getLayout(<Component {...pageProps} />)}</PlayerWrapper>
          </UserWrapper>
          {process.env.NODE_ENV === "production" && <Analytics />}
        </ErrorBoundary>
      </ChakraProvider>
    </MDXProvider>
  )
}

const UserWrapper = ({ children }) => {
  const [userResponse, { isLoading, refetch, isFetched }] = useQuery(getCurrentUser, null, {
    suspense: false,
    networkMode: "always",
  })
  const { user } = userResponse || { user: null }
  const userContext = { user, refetch, isLoading, isFetched }

  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
}

const PlayerWrapper = ({ children }) => {
  const [playlistModalMode, setPlaylistModalMode] = useState<PlaylistModalMode>(null)
  const [currentHowl, setHowl] = useState<{
    howl: any
    url: string
    state: PlayerContextT["state"]
  } | null>(null)

  const onClose = () => setPlaylistModalMode(null)

  const play = (urls: string[]) => {
    const [url, ...queue] = urls
    if (!url) {
      if (currentHowl?.howl?.playing()) {
        currentHowl.howl.pause()
      }
      setHowl(null)
      return
    }

    if (currentHowl?.howl && currentHowl.url === url) {
      if (currentHowl.howl.playing()) {
        currentHowl.howl.pause()
        setHowl({ howl: currentHowl.howl, url, state: "paused" })
      } else {
        currentHowl.howl.play()
      }
      return
    }
    var howl = new Howl({
      src: [url],
      html5: true,
    })
    howl.play()
    setHowl({ howl, url, state: "loading" })
    howl.on("play", () => {
      setHowl({ howl, url, state: "playing" })
    })
    howl.on("end", () => {
      play(queue)
    })
  }

  useEffect(() => {
    return () => {
      if (currentHowl?.howl) {
        currentHowl.howl.stop()
      }
    }
  }, [currentHowl?.howl])

  return (
    <PlayerContext.Provider
      value={{
        play,
        url: currentHowl?.url,
        state: currentHowl?.state || "paused",
        setPlaylistModalMode,
      }}
    >
      {children}
      <PlaylistModal mode={playlistModalMode} onClose={onClose} />
    </PlayerContext.Provider>
  )
}

export default withBlitz(MyApp)

// export const getServerSideProps = gSSP(async (stuff) => {
//   const colorMode = stuff.req.cookies["chakra-ui-color-mode"]

//   const cookies = colorMode ? `chakra-ui-color-mode=${colorMode};` : ""

//   console.log("wow", cookies)
//   return {
//     props: {
//       cookies,
//     },
//   }
// })
